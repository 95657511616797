import React, { useState, useEffect, useRef } from "react";
import { getAppointments, createAppointment, deleteAppointment, updateAppointment } from "./services/api";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { addLocale } from 'primereact/api';
import {Toast} from 'primereact/toast';
import ReactCalendar from "react-calendar";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "react-calendar/dist/Calendar.css";
import "./index.css";
import { Paginator } from "primereact/paginator";
const turkishLocale = {
    firstDayOfWeek: 1,
    dayNames: ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"],
    dayNamesShort: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
    dayNamesMin: ["Pz", "Pt", "Sa", "Ça", "Pe", "Cu", "Ct"],
    monthNames: [
        "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
        "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
    ],
    monthNamesShort: ["Oca", "Şub", "Mar", "Nis", "May", "Haz", "Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"],
    today: "Bugün",
    clear: "Temizle",
    dateFormat: "dd/mm/yy",
    weekHeader: "Hf"
};
addLocale('tr', turkishLocale);


const App = () => {
    const [appointments, setAppointments] = useState([]);
    const [filteredAppointments, setFilteredAppointments] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [newAppointment, setNewAppointment] = useState({
        name: "",
        phone: "",
        description: "",
        date: null,
    });
    const [dialogVisible, setDialogVisible] = useState(false);
    const [dialogMode, setDialogMode] = useState("add"); // 'add', 'edit', 'view'
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [pageState, setPageState] = useState({
        first: 0,
        rows: 50,
        sortField: null,
        sortOrder: -1,

    });
    const [selectedDay,setSelectedDay] = useState(null);

    const toast = useRef(null);


    const [totalRecords, setTotalRecords] = useState(0);
    useEffect(() => {
        loadAppointments();
    }, [pageState, searchTerm,selectedDay]);
    useEffect(() => {
        const results = filteredAppointments.filter(
            (appointment) =>
                appointment.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                appointment.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
                appointment.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
                new Date(appointment.date).toLocaleString().toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredAppointments(results);
    }, [searchTerm, pageState,selectedDay]);

    const loadAppointments = async () => {
        const response = await getAppointments(selectedDay ? new Date(selectedDay)?.toLocaleDateString() : null,pageState.first / pageState.rows + 1, pageState.rows, searchTerm, pageState.sortField, pageState.sortOrder);
        setAppointments(response.data.appointments);
        setFilteredAppointments(response.data.filteredAppointments);
        setTotalRecords(response.data.totalRecords);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewAppointment((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleDateChange = (e) => {
        setNewAppointment((prevState) => ({
            ...prevState,
            date: e.value,
        }));
    };
    const handleSubmit = async () => {
        try {
            const appon = { ...newAppointment };
            if(!appon['name']) toast.current.show({severity:'error',life:2500, summary:'Hata', detail:'İsim alanı boş bırakılamaz.'});
            if(!appon['phone']) toast.current.show({severity:'error',life:2500, summary:'Hata', detail:'Telefon alanı boş bırakılamaz.'});
            if(!appon['description']) toast.current.show({severity:'error',life:2500, summary:'Hata', detail:'Açıklama alanı boş bırakılamaz.'});
            if(!appon['date']) return toast.current.show({severity:'error',life:2500, summary:'Hata', detail:'Tarih ve saat alanı boş bırakılamaz.'});
            appon['date'] = new Date(appon.date).toISOString();
            if (dialogMode === "add") {
                await createAppointment(appon);
            } else if (dialogMode === "edit") {
                await updateAppointment(selectedAppointment.id, appon);
            }
            loadAppointments();
            setDialogVisible(false);
            setNewAppointment({ name: "", phone: "", description: "", date: null });
        } catch (error) {
            console.error("Error submitting appointment:", error);
        }
    };

    const handleDelete = async (appointmentId) => {
        try {
            await deleteAppointment(appointmentId);
            loadAppointments();
        } catch (error) {
            console.error("Error deleting appointment:", error);
        }
    };

    const renderFooter = () => {
        return (
            <div className="flex gap-2">
                <Button label="İptal" icon="pi pi-times" onClick={() => setDialogVisible(false)} className="p-button-text" />
                {dialogMode !== "view" && <Button label={dialogMode === "add" ? "Ekle" : "Kaydet"} icon="pi pi-check" onClick={handleSubmit} autoFocus />}
            </div>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="flex gap-2">
                    <Button icon="pi pi-eye" className="p-button-rounded p-button-text p-button-info" onClick={() => viewAppointment(rowData)} />
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-warning" onClick={() => editAppointment(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger" onClick={() => handleDelete(rowData.id)} />
                </div>
            </React.Fragment>
        );
    };

    const viewAppointment = (appointment) => {
        const appon = { ...appointment };
        appon['date'] = new Date(appon.date);
        setNewAppointment(appon);
        setSelectedAppointment(appon);
        setDialogMode("view");
        setDialogVisible(true);
    };

    const editAppointment = (appointment) => {
        let app = { ...appointment };
        app['date'] = new Date(app['date'])
        setNewAppointment(app);
        setSelectedAppointment(appointment);
        setDialogMode("edit");
        setDialogVisible(true);
    };

    const tileClassName = ({ date, view }) => {
        if (view === "month") {

            const dateString = new Date(new Date(date).setDate(new Date(date).getDate() + 1)).toISOString().split("T")[0];
            const hasAppointment = appointments.some((app) => new Date(new Date(app.date).setHours(new Date(app.date).getHours() + 3)).toISOString().split("T")[0] === dateString);
            return hasAppointment ? "bg-red-500 text-white" : null;
        }
    };

    useEffect(() => {
        setPageState({
            first: 0,
            rows: 50,
            sortField: null,
            sortOrder: -1,

        })
    }, [searchTerm])
    useEffect(() => {
        if (!dialogVisible) {
            setNewAppointment({
                name: "",
                phone: "",
                description: "",
                date: null,
            });
            setSelectedAppointment(null)
        }
    }, [dialogVisible]);
    return (
        <div className="container">
            <Toast ref={toast} />

            <div className="flex justify-content-center">
                <img src="/logo.jpeg" className="h-5rem"/>
            </div>
            <h1 className="my-0">Randevu Takip</h1>
            <div className="w-12 flex justify-content-center">
                <ReactCalendar value={selectedDay ? new Date(selectedDay) : new Date()} onClickDay={(e)=>
                    {
                        if(!selectedDay) return setSelectedDay(e);
                        if(new Date(selectedDay).toLocaleDateString() == new Date(e).toLocaleDateString()){
                            return setSelectedDay(null);
                        }else{
                            return setSelectedDay(e);
                        }
                    
                    }
                    
                    }
                    
                    locale="tr" tileClassName={tileClassName} />

            </div>
            <Button
                className="my-1"
                label="Yeni Randevu Ekle"
                icon="pi pi-plus"
                onClick={() => {
                    setDialogMode("add");
                    setDialogVisible(true);
                }}
            />

            <input type="text" placeholder="Ara..." value={searchTerm}
                className="outline-none"
                onChange={(e) => setSearchTerm(e.target.value)} style={{ marginBottom: "10px", padding: "8px", width: "100%" }} />

            <Dialog header={dialogMode === "view" ? "Randevu Görüntüle" : dialogMode === "edit" ? "Randevu Düzenle" : "Yeni Randevu"} visible={dialogVisible}
                className="md:w-6 w-12" footer={renderFooter()} onHide={() => setDialogVisible(false)}>
                <div className="form-container flex flex-column gap-2">
                    <input className="outline-none" type="text" name="name" placeholder="Ad" value={newAppointment.name} onChange={handleChange} required disabled={dialogMode === "view"} />
                    <input className="outline-none" type="text" name="phone" placeholder="Telefon" value={newAppointment.phone} onChange={handleChange} required disabled={dialogMode === "view"} />
                    <textarea name="description" placeholder="Açıklama" value={newAppointment.description} onChange={handleChange} required
                        className="md:h-auto outline-none"
                        rows={8}
                        disabled={dialogMode === "view"} />
                    <Calendar

                        locale={'tr'}
                        value={newAppointment.date}
                        onChange={handleDateChange}
                        showTime
                        showIcon
                        className="flex flex-column md:flex-row"

                        placeholder="Tarih ve Saat Seçin"
                        required
                        disabled={dialogMode === "view"} />
                </div>
            </Dialog>

            <DataTable
                value={filteredAppointments}
                lazy
                paginator

                rows={pageState.rows}
                first={pageState.first}
                totalRecords={totalRecords}
                sortField={pageState.sortField}
                sortOrder={pageState.sortOrder}
                onSort={(e) => { setPageState(prev => ({ ...prev, sortField: e.sortField, sortOrder: e.sortOrder })) }}
                onPage={(e) => { setPageState(prev => ({ ...prev, first: e.first })) }}
                className="p-datatable-gridlines hidden md:block">
                <Column field="name" header="Ad" sortable />
                <Column field="phone" header="Telefon" sortable />
                <Column field="description" header="Açıklama" sortable />
                <Column field="date" header="Tarih ve Saat" sortable body={(rowData) => {return (new Date(rowData.date).toLocaleString('tr-TR')) }} />
                <Column body={actionBodyTemplate} header="Aksiyonlar" />
            </DataTable>

            <div className="md:hidden">
                {
                    filteredAppointments.map((app, key) => (
                        <div key={key} className="p-2 my-2 border-1 border-gray-400 border-round-md">
                            <div className="flex justify-content-between align-items-center">
                                <div className="font-bold text-lg text-gray-800">{app.name}</div>
                                <div className="font-bold text-gray-500 text-xs">
                                    <div className="flex align-items-center gap-1">{new Date(app.date).toLocaleString('tr-TR', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })} <i className="pi pi-clock" /></div>

                                </div>
                            </div>
                            <div className="text-gray-800 ">{app.phone}</div>
                            <div className="text-blue-600 font-bold">Açıklama: <span className="text-gray-800 font-medium">{app.description}</span></div>
                            <div className="pt-3">
                                {
                                    actionBodyTemplate(app)
                                }
                            </div>
                        </div>
                    ))
                }
                <Paginator first={pageState.first} rows={pageState.rows} totalRecords={totalRecords} onPageChange={(e) => { setPageState(prev => ({ ...prev, ...e })) }}
                />

            </div>



        </div>
    );
};

export default App;
